<template>
  <div class="row m-0">
    <div class="col-12 d-lg-none p-0">
      <div class="menu_icon">
        <div class="cursor text-left">
          <ios-menu-icon v-if="isNavOpen" class="ninety_degree" @click="closeNav"/>
          <ios-menu-icon v-else @click="openNav"/>
        </div>
      </div>
    </div>
    <!--MOBILE MENU-->
    <transition name="slide">
      <MobileMenu @closeMenu="closeNav" v-if="isMobile && isNavOpen" />
    </transition>
    <!--END MOBILE MENU -->
    <!--DESKTOP MENU-->
    <div class="col-12 px-0 d-none d-lg-block">
      <MenuItems @closeMenu="closeNav" />
    </div>
    <!--END DESKTOP MENU -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    MenuItems: defineAsyncComponent(() => import('../components/MenuItems.vue')),
    MobileMenu: defineAsyncComponent(() => import('./MobileMenu.vue')),
    'ios-menu-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-menu.vue'))
  },
  name: 'MainMenu',
  data () {
    return {
      isNavOpen: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    openNav () {
      this.isNavOpen = true
      document.body.style.overflow = 'hidden'
    },
    closeNav () {
      this.isNavOpen = false
      document.body.style.overflow = ''
    }
  }
}
</script>

<style scoped>
.above_all {
  z-index: 5;
  position: relative;
}
.ninety_degree {
  transform: rotate(90deg);
}
.menu_icon {
  background-color: transparent;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  font-size: 28px;
  color: var(--gold-color);
  text-align: center;
  padding-top: 12px;
}
.slide-enter-active {
  transition: all 0.5s ease;
}
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter-from {
  transform: translateX(-100%);
  opacity: 1;
}
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 1;
}
</style>
